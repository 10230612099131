import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Grid,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//utils
import { generateOTP } from "../../util/generateOTP";
import { sendOtp } from "../../util/sendOtp";
import { signInGuestUser } from "../../util/LoginGuestUser";

//actions
import { sendOTP, setCurrentUser } from "../../Redux/actions/User/User.action";

//firebase
import firebase from "../../firebase/firebase";
import Timer from "react-compound-timer";

export const WishListLoginModal = ({
  setOpenLoginToComplete,
  id,
  defaultSelectSizePrice,
  product,
  setSnackbar,
  setsnackbarMessage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailorPhone, setemailorPhone] = useState("");
  const [OTP, setOTP] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [OTPerror, setOTPerror] = useState("");
  const [OTPloading, setOTPLoading] = useState(false);
  const [otpSendLoading, setotpSendLoading] = useState(false);
  const [CurrentContainer, setCurrentContainer] = useState("main");
  const [variable, setvariable] = useState();
  const [valueCountryCode, setvalueCountryCode] = useState("");
  const [activeResendButton, setactiveResendButton] = useState(true);
  const [focused, setfocused] = useState(false);
  const countryCode = useSelector((state) => state?.user?.countryByIp);
  const userOtp = useSelector((state) => state?.user?.sendOTP);

  const baseCountry = useSelector(
    (state) => state?.logoCaption?.selectedBaseCountry
  );
  const baseCountryPhone = baseCountry && baseCountry?.selected_country_phone;
  const baseCountryCode = baseCountry && baseCountry?.selected_country_code;
  useEffect(() => {
    setvalueCountryCode(
      countryCode?.country_phone ? countryCode?.country_phone : baseCountryPhone
    );
  }, [countryCode, baseCountryPhone]);
  const renderOtpVerify = () => {
    const stateOTP = userOtp.OTP;
    let recaptchaWrapperRef;
    const userId = userOtp.phone ? userOtp.phone : userOtp.email;
    const handleChange = (e) => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      // Check if they hit the max character length
      if (value.length >= maxLength) {
        if (value.trim() === "") {
          return;
        }
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 6) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
            return;
          }
        }
      } else {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) <= 6) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
            return;
          }
        }
      }
    };

    const handleOTPSubmit = async (e) => {
      e.preventDefault();
      setOTPerror("");
      setOTPLoading(true);
      if (userOtp?.OTP) {
        let count = 0;
        let eload;

        for (let property in OTP) {
          console.log(stateOTP.charAt(count) === OTP[property]);
          eload = OTP[property] === stateOTP.charAt(count);
          count += 1;
        }

        console.log(eload);
        if (!eload) {
          return setOTPerror("Invalid OTP");
        } else {
          setOTPerror("");
        }
      }

      try {
        const guestuserCountryCode = valueCountryCode;

        if (!userOtp.OTP) {
          let otp = "";
          for (let property in OTP) {
            otp += OTP[property];
          }

          console.log(otp);
          variable
            .confirm(otp)
            .then(async (res) => {
              const fuser = res.user;
              console.log("signed in ", fuser);
              const fres = await signInGuestUser(
                emailorPhone,
                guestuserCountryCode
              );

              localStorage.setItem("token", fres?.token);
              dispatch(setCurrentUser());
              setOpenLoginToComplete(false);
              setOTPLoading(false);
              history.push({
                pathname: `/my-wishlist`,
              });
            })
            .catch((error) => {
              console.log(error);
              setOTPerror("Invalid OTP");
              setOTPLoading(false);
              return;
            });
        }

        setOTP({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        });
        if (userOtp.OTP) {
          const res = await signInGuestUser(emailorPhone, guestuserCountryCode);
          console.log(res);
          if (res?.status === "fail") {
            if (localStorage.getItem("token") === undefined) {
              localStorage.removeItem("token");
              localStorage.setItem("token", res?.token);
            } else {
              localStorage.setItem("token", res?.token);
            }
          }
          if (res?.status === "success") {
            if (localStorage.getItem("token") === undefined) {
              localStorage.removeItem("token");
              localStorage.setItem("token", res?.token);
            } else {
              localStorage.setItem("token", res?.token);
            }
          }
          dispatch(setCurrentUser());
          setOTPLoading(false);
          setOpenLoginToComplete(false);
          history.push({
            pathname: `/my-wishlist`,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const resendOTP = async () => {
      try {
        if (userId !== "") {
          const number = valueCountryCode + userId;
          console.log("Phone Number", number);
          const resend = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
            }
          );

          firebase
            .auth()
            .signInWithPhoneNumber(number, resend)
            .then(function (confirmationResult) {
              setvariable(confirmationResult);
              if (resend && recaptchaWrapperRef) {
                resend.clear();
                recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div
        style={{
          backgroundColor: "#fff",
          position: "absolute",
          bottom: "0",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}
      >
        <Container>
          <form onSubmit={handleOTPSubmit}>
            <div ref={(ref) => (recaptchaWrapperRef = ref)}>
              <div id="recaptcha-container"></div>
            </div>
            <Grid container alignItems="center">
              {/* <ArrowBack style={{ fontSize: "25px" }} /> */}
              <div
                style={{
                  paddingLeft: "10px",
                  fontSize: "17px",
                  // fontWeight: "600",
                  // display: "inline-block",
                }}
              >
                Enter OTP for {userId}
              </div>
              <Button
                color="primary"
                onClick={() => setCurrentContainer("main")}
              >
                Edit
              </Button>
              <Divider />
            </Grid>

            <Box height="25px" />
            {/* <TextField variant="outlined" fullWidth label="Enter  OTP" /> */}
            {OTPerror && <Alert severity="warning">{OTPerror}</Alert>}
            <Grid container justify="space-around">
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp1.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp1: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-1"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp2.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp2: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-2"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp3.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp3: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-3"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp4.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp4: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-4"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp5.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp5: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-5"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={OTP.otp6.trim()}
                  onChange={(e) => {
                    setOTP({ ...OTP, otp6: e.target.value.trim() });
                    // handleChange(e);
                  }}
                  onKeyUp={(e) => {
                    handleChange(e);
                  }}
                  inputMargin="0px"
                  required={true}
                  maxLength={1}
                  padding="12px 10px"
                  type="tel"
                  name="otp-6"
                  style={{ margin: "5px" }}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "30px" },
                    minLength: 1,
                    maxLength: 1,
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Box height="15px" />
            <Grid container justify="space-between">
              <Grid xs={8}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={OTP.otp6 !== "" ? OTPloading : true}
                >
                  {OTPloading ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    "Verify"
                  )}
                </Button>
              </Grid>
              <Grid xs={3}>
                <Timer
                  initialTime={15000}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 15,
                      callback: () => {
                        setactiveResendButton(false);
                      },
                    },
                  ]}
                >
                  {({ reset, start }) => (
                    <div>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        color="secondary"
                        disabled={activeResendButton}
                        onClick={() => {
                          resendOTP();
                          reset();
                          setactiveResendButton(true);
                          start();
                        }}
                      >
                        {activeResendButton ? <Timer.Seconds /> : "Resend"}
                      </Button>
                    </div>
                  )}
                </Timer>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  };

  const enterEmailOrPhone = () => {
    let recaptchaWrapperRef;
    const handleEmailEnterSubmit = async (e) => {
      e.preventDefault();
      setotpSendLoading(true);
      const OTP = generateOTP();
      let email = "";
      let phone = "";
      let dispatchUser = {
        email: "",
        OTP: "",
        phone: "",
      };
      emailorPhone.match(/^-?\d+$/)
        ? (phone = emailorPhone)
        : (email = emailorPhone);

      let sendOTPvariables = {
        email: "",
        phone: "",
        OTP: "",
        country_code: valueCountryCode,
      };
      try {
        setotpSendLoading(true);

        // const res = await guestUserChecking(emailorPhone);

        if (email.trim() !== "") {
          sendOTPvariables = {
            ...sendOTPvariables,
            phone,
            email: email,
            OTP: OTP,
          };
          dispatchUser = {
            email: email,
            OTP: OTP,
          };
          const data = await sendOtp(sendOTPvariables);
          if (data?.status === "success") {
            dispatch(sendOTP(dispatchUser));
            setCurrentContainer("otp");
            setotpSendLoading(false);
          } else if (data?.status === "fail") {
            setsnackbarMessage(data?.response);
            setSnackbar(true);
            setotpSendLoading(false);
          }
        }
        if (phone.trim() !== "") {
          dispatchUser = {
            phone: phone,
          };
          const fphone = valueCountryCode + emailorPhone;
          const appVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
            }
          );
          dispatch(sendOTP(dispatchUser));

          firebase
            .auth()
            .signInWithPhoneNumber(fphone, appVerifier)
            .then(function (confirmationResult) {
              setvariable(confirmationResult);
              if (appVerifier && recaptchaWrapperRef) {
                appVerifier.clear();
                recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
              }
              setCurrentContainer("otp");
              setotpSendLoading(false);
            })
            .catch(function (error) {
              console.log(error);
              setsnackbarMessage(error.code);
              setSnackbar(true);
              setotpSendLoading(false);
              setOpenLoginToComplete(false);
              // window.location.href = `/product-details/${product?.product_name}/${id}`;
            });
        }
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <div>
        <form onSubmit={handleEmailEnterSubmit}>
          <div ref={(ref) => (recaptchaWrapperRef = ref)}>
            <div id="recaptcha-container"></div>
          </div>
          <Container
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              bottom: "0",
              paddingTop: "25px",
              paddingBottom: "25px",
              height: focused ? 350 : 250,
            }}
          >
            <div>
              <Box height="10px" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  margin: "10px 0",
                }}
              >
                Login to complete your shopping
              </div>
              <Box height="20px" />
              <TextField
                fullWidth
                variant="outlined"
                value={emailorPhone.trim()}
                onFocus={() => setfocused(true)}
                onChange={(e) => setemailorPhone(e.target.value.trim())}
                label="Phone Number"
                color="secondary"
                type="tel"
                id="phone"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <PhoneInput
                      enableSearch={true}
                      onlyCountries={["in", "bd", "us"]}
                      country={
                        countryCode?.country_code
                          ? countryCode?.country_code.toLowerCase()
                          : baseCountryCode.toLowerCase()
                      }
                      // value={valueCountryCode}
                      onChange={(e) => {
                        setvalueCountryCode("+" + e);
                      }}
                      onFocus={() => {
                        const nextSibling = document.getElementById("phone");
                        if (nextSibling !== null) {
                          nextSibling.focus();
                          return;
                        }
                      }}
                      style={{
                        width: "100px",
                      }}
                    />
                  ),
                }}
              />

              <Box height="15px" />

              <Button
                size="large"
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={emailorPhone.length > 9 ? otpSendLoading : true}
              >
                {otpSendLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  "SEND OTP"
                )}
              </Button>
            </div>
          </Container>
        </form>
      </div>
    );
  };

  return (
    <div>
      {CurrentContainer === "main"
        ? enterEmailOrPhone()
        : CurrentContainer === "otp" && renderOtpVerify()}
    </div>
  );
};
